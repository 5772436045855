<template>
  <div class="com">
    <div class="prism-player-box">
      <vue-aliplayer-v2
        v-if="options.vid != ''"
        ref="VueAliplayerV2"
        :options="options"
      />
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import VueAliplayerV2 from "vue-aliplayer-v2";
import { getDivisionContentByContentId } from "@api/public";
export default {
  components: { VueAliplayerV2 },
  props: {},

  data() {
    return {
      queryWhere: {},
      options: {
        autoplay: false,
        height: "100%",
        playsinline: true,
        vid: "",
        playauth: "",
        encryptType: 1,
        cover: "",
      },
      divisionContent: {},
    };
  },
  mounted: function () {
    let that = this;
    getDivisionContentByContentId(that.queryWhere).then(function (res) {
      that.$set(that, "divisionContent", res.data);
      that.$set(that.options, "vid", res.data.course_vid);
      that.$set(that.options, "playauth", res.data.playauth.PlayAuth);
    });
  },
  created: function () {
    this.queryWhere = this.$route.query;
    document.title = this.$route.meta.title;
  },
  methods: {},
  destroyed: function () {
    $(".layui-layer-shade").remove();
  },
};
</script>

<style scoped>
html,
body {
  width: 100% !important;
  height: 100% !important;
}
.com {
  width: 100%;
  height: 1182px;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.prism-player-box {
  position: relative;
  height: 100% !important;
  width: 100%;
  z-index: 2000;
  background-color: #000;
}

.prism-player-box .prism-player {
  height: 100% !important;
}
</style>
